exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-call-for-lightning-talks-js": () => import("./../../../src/pages/call-for-lightning-talks.js" /* webpackChunkName: "component---src-pages-call-for-lightning-talks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livestream-js": () => import("./../../../src/pages/livestream.js" /* webpackChunkName: "component---src-pages-livestream-js" */),
  "component---src-pages-practical-info-js": () => import("./../../../src/pages/practical-info.js" /* webpackChunkName: "component---src-pages-practical-info-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-templates-letter-page-js": () => import("./../../../src/templates/letter-page.js" /* webpackChunkName: "component---src-templates-letter-page-js" */),
  "component---src-templates-people-page-js": () => import("./../../../src/templates/people-page.js" /* webpackChunkName: "component---src-templates-people-page-js" */)
}

